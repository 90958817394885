/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

/* Include Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Customizations */
@layer utilities {
  .btn:hover,
  .btn:focus {
    background-color: blue;
  }
}
@variants hover, focus {
  .btn {
    background-color: #3f930d;
  }
}

/* Additional styles */
.toastBody {
  font-family: 'Roboto', sans-serif;
  color: #000;
}

.toastProgress {
  background: #000 !important;
}

.toastBodyDark {
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

.toastProgressDark {
  background: #fff !important;
}

.textarea-container {
  position: relative;
}

.textarea {
  resize: none;
  overflow-y: hidden;
}

.textarea::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

